<div class="container-fluid" *ngIf="!loading">
  <div class="card">
    <div class="card-header">
      <h5>Foydalanuvchini Tahrirlash</h5>
    </div>
    <div class="card-body">
      <form
        [formGroup]="editUserForm"
        (ngSubmit)="onSubmit()"
        class="needs-validation"
      >
        <div class="form-group row">
          <label for="first_name" class="col-xl-3 col-md-4"> Ismi </label>
          <div class="col-md-8">
            <input
              class="form-control"
              formControlName="first_name"
              id="first_name"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="second_name" class="col-xl-3 col-md-4">
            Familiyasi
          </label>
          <div class="col-md-8">
            <input
              class="form-control"
              formControlName="second_name"
              id="second_name"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="patronymic" class="col-xl-3 col-md-4"> Sharifi </label>
          <div class="col-md-8">
            <input
              class="form-control"
              formControlName="patronymic"
              id="patronymic"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="email" class="col-xl-3 col-md-4"> Email </label>
          <div class="col-md-8">
            <input
              class="form-control"
              formControlName="email"
              id="email"
              type="email"
            />
          </div>
        </div>
        <div class="form-group row">
          <label for="reception_number" class="col-xl-3 col-md-4">
            Qabul raqami
          </label>
          <div class="col-md-8">
            <input
              class="form-control"
              formControlName="reception_number"
              id="reception_number"
            />
          </div>
        </div>
        <div class="form-group">
          <label>Xizmatlar</label>
          <div
            class="form-check"
            *ngFor="let service of servicesData; let i = index"
          >
            <input
              class="form-check-input"
              type="checkbox"
              [formControlName]="i"
              [id]="'service-' + i"
            />
            <label class="form-check-label" [for]="'service-' + i">
              {{ service.title }}
            </label>
          </div>
        </div>
        <div class="text-right">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="!editUserForm.valid"
          >
            Yangilash
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<div *ngIf="loading" class="lds-wrap">
  <div class="lds-dual-ring"></div>
</div>
