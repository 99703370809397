<div class="modal-backdrop" *ngIf="isVisible"></div>
<div
  class="modal fullscreen-modal"
  tabindex="-1"
  *ngIf="isVisible"
  role="dialog"
>
  <div class="modal-dialog modal-fullscreen" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ title }}</h5>
        <button
          type="button"
          class="close"
          (click)="closeModal()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="closeModal()">
          Bekor qilish
        </button>
        <button type="button" class="btn btn-primary" (click)="onSave()">
          Saqlash
        </button>
      </div>
    </div>
  </div>
</div>
