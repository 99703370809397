<div class="container-fluid" *ngIf="!loading">
  <div class="card">
    <div class="card-header">
      <h5>Foydalanuvchilar Ro'yxati</h5>
    </div>
    <div class="card-body">
      <div id="userTable" class="table-responsive">
        <ng2-smart-table
          [settings]="settings"
          [source]="user_list"
          (custom)="onCustomAction($event)"
        ></ng2-smart-table>
      </div>
    </div>
  </div>
</div>

<!-- Delete Confirmation Modal -->
<app-modal
  [isVisible]="isDeleteModalVisible"
  [title]="'Foydalanuvchini o‘chirishni tasdiqlang'"
  (save)="confirmDelete()"
  (close)="cancelDelete()"
>
  <p>Ushbu foydalanuvchini o‘chirishni xohlaysizmi?</p>
</app-modal>
<app-modal
  [isVisible]="isEditModalVisible"
  [title]="'Foydalanuvchini Tahrirlash'"
  (save)="saveUser()"
  (close)="closeModal()"
>
  <form [formGroup]="editUserForm">
    <div class="form-group">
      <label>Ismi</label>
      <input
        class="form-control"
        formControlName="first_name"
        placeholder="Ismi"
      />
    </div>
    <div class="form-group">
      <label>Familiyasi</label>
      <input
        class="form-control"
        formControlName="second_name"
        placeholder="Familiyasi"
      />
    </div>
    <div class="form-group">
      <label>Sharifi</label>
      <input
        class="form-control"
        formControlName="patronymic"
        placeholder="Sharifi"
      />
    </div>
    <div class="form-group">
      <label>Email</label>
      <input
        class="form-control"
        formControlName="email"
        type="email"
        placeholder="Email"
      />
    </div>
    <div class="form-group">
      <label>Parol</label>
      <input
        class="form-control"
        formControlName="password"
        type="password"
        placeholder="Parol"
      />
    </div>
    <div class="form-group">
      <label>Qabul raqami</label>
      <input
        class="form-control"
        formControlName="reception_number"
        type="reception_number"
        placeholder="Qabul raqami"
      />
    </div>
    <div class="form-group">
      <label>Xizmatlar</label>
      <div
        *ngFor="let service of servicesData; let i = index"
        class="form-check"
      >
        <input
          class="form-check-input"
          type="checkbox"
          [id]="'service-' + i"
          [checked]="selectedServices.includes(service.title)"
          (change)="toggleService(service.title)"
        />
        <label class="form-check-label" [for]="'service-' + i">{{
          service.title
        }}</label>
      </div>
    </div>
  </form>
</app-modal>
