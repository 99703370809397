import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { NavService } from '../../service/nav.service';
import { DataService } from '../../service/data.service';
import { HttpService } from '../../service/http.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounce, zoomOut, zoomIn, fadeIn, bounceIn } from 'ng-animate';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
  animations: [
    trigger('animateRoute', [
      transition(
        '* => *',
        useAnimation(fadeIn, {
          // Set the duration to 5seconds and delay to 2 seconds
          //params: { timing: 3}
        })
      ),
    ]),
  ],
})
export class ContentLayoutComponent implements OnInit {
  public right_side_bar: boolean;
  public layoutType: string = 'RTL';
  public layoutClass: boolean = false;
  loading: boolean = false;
  user: any;
  constructor(
    public authServices: AuthService,
    public navServices: NavService,
    private dataService: DataService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public rightSidebar($event) {
    this.right_side_bar = $event;
  }

  public clickRtl(val) {
    if (val === 'RTL') {
      document.body.className = 'rtl';
      this.layoutClass = true;
      this.layoutType = 'LTR';
    } else {
      document.body.className = '';
      this.layoutClass = false;
      this.layoutType = 'RTL';
    }
  }

  ngOnInit() {
    this.loading = false;
    this.user = this.dataService.user;

    console.log('ContentLayoutComponent ngOnInit');
    // this.authServices.user.subscribe(user=>{
    //   console.log(user)
    //   this.user = user;
    //   this.loading = false;
    // })
  }
}
